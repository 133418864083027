"use client";

import React, {useState, useEffect} from "react";
import Link from "next/link";
import {SubmissionView} from "@/types";

export default function TaskProjectSubmissions() {
  const [projecSubmissions, setProjectSubmissions] = useState<SubmissionView[]>([]); // Store distinct project IDs
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  useEffect(() => {
    const fetchProjectIds = async () => {
      setLoading(true);
      setError(null);

      try {
        // Call the API to get distinct project IDs
        const response = await fetch("/api/submissions/projects");
        if (!response.ok) {
          throw new Error("Failed to fetch project IDs");
        }

        const data: SubmissionView[] = await response.json();
        setProjectSubmissions(data);
      } catch (err) {
        console.error("Error fetching project IDs:", err);
        setError("Unable to fetch projects. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectIds();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error) {
    return <p className="text-error text-center">{error}</p>;
  }

  // Render the list of project IDs
  return (
    <div className="w-full h-full p-6">
      <h1 className="text-2xl font-bold mb-4">Project Submissions</h1>
      {projecSubmissions.length === 0 ? (
        <p className="text-gray-500">No projects found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {projecSubmissions.map((item) => (
            <Link key={item.project_id} href={`/tasks/process-submissions/${item.project_id}`} className="btn btn-success">
              {item.project_name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
