"use client";

import React, {useEffect, useState} from "react";

export default function SubmissionStats() {
  const [counts, setCounts] = useState<{
    totalContacted: number;
    totalNotContacted: number;
    totalValidated: number;
    totalNotValidated: number;
    totalNeedsAttention: number;
    totalProcessed: number;
    totalWaitingToBeProcessed: number;
  } | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        setLoading(true);
        const response = await fetch("/api/submissions/counts");
        if (!response.ok) throw new Error("Failed to fetch counts");

        const data = await response.json();
        setCounts(data);
      } catch (error) {
        console.error("Error fetching submission counts:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !counts) {
    return <div>Error loading submission stats.</div>;
  }

  return (
    <div className="grid grid-cols-4  gap-8 p-6 shadow rounded-lg text-center">
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-green-400 font-semibold mb-3">Processed</div>
        <div className="text-4xl font-bold text-green-200">{counts.totalProcessed}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-green-400 font-semibold mb-3">Validated</div>
        <div className="text-4xl font-bold text-green-200">{counts.totalValidated}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-green-400 font-semibold mb-3">Contacted</div>
        <div className="text-4xl font-bold text-green-200">{counts.totalContacted}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-yellow-400 font-semibold mb-3">Needs Attention</div>
        <div className="text-4xl font-bold text-yellow-200">{counts.totalNeedsAttention}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-red-400 font-semibold mb-3">Waiting</div>
        <div className="text-4xl font-bold text-red-200">{counts.totalWaitingToBeProcessed}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-red-400 font-semibold mb-3">Not Validated</div>
        <div className="text-4xl font-bold text-red-200">{counts.totalNotValidated}</div>
      </div>
      <div className="bg-base-200 p-4 rounded-lg shadow">
        <div className="text-red-400 font-semibold mb-3">Not Contacted</div>
        <div className="text-4xl font-bold text-red-200">{counts.totalNotContacted}</div>
      </div>
    </div>
  );
}
